import React, { useState } from "react"
import { Col, Container, Row, Form, Button } from "react-bootstrap"
import Layout from "../../components/layout/Index"
import Title from "../../components/commons/page/title/Index"
import ListGallery from "../../components/content/gallery/list/Index"
// import Pagination from "../../components/commons/pagination/index"
import { graphql, Link } from "gatsby"
import { withTrans } from "../../i18n/withTrans"
import { makeStyles } from "@material-ui/core/styles"
import Pagination from "@material-ui/lab/Pagination"

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}))

const Index = ({ data, location: { origin }, t, i18n }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTextTemp, setSearchTextTemp] = useState("")
  const [searchText, setSearchText] = useState("")
  const classes = useStyles()
  const language = i18n.language
  const gallery = data?.allWpGalleryGks?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )
  const pageSize = 12
  const pagesCount = Math.ceil(
    gallery.filter(item =>
      item.title.toLowerCase().includes(searchText.toLowerCase())
    ).length / pageSize
  )
  const regexGetHrefAttribute = /<a[^>]*href=["']([^"']*)["']/gm

  const getGalleryData = wysiwygMultipleGallery => {
    const getHrefAttribute = wysiwygMultipleGallery?.match(
      regexGetHrefAttribute
    )
    const getAllGalleryLink = getHrefAttribute?.map(
      item => `${item.substring(9, item.length - 1).replace(/ /g, "%20")}`
    )
    return getAllGalleryLink
  }

  const handlePageClick = (e, i) => {
    setCurrentPage(i)
  }

  const handlePreviousClick = (e, currentPage) => {
    setCurrentPage(currentPage - 1)
  }

  const handleNextClick = (e, currentPage) => {
    setCurrentPage(currentPage + 1)
  }

  const onSearch = e => {
    e.preventDefault()
    setSearchText(searchTextTemp)
  }

  return (
    <Layout>
      <Container className="remove-padding page-container article-container">
        <Row className="justify-content-between align-items-center mb-4">
          <Col md={6} className="page-title m-0">
            <span>{t("header.gallery")}</span>
          </Col>
          <Col md={6} xs={12}>
            <Row className="justify-content-between">
              <Col md={12} className="d-flex justify-content-end">
                <Form.Control
                  onChange={e => setSearchTextTemp(e.target.value)}
                  placeholder={t("article.enterKeyword")}
                />
                <Button type="submit" onClick={e => onSearch(e)}>
                  {/* {t("search")} */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Title label="Gallery" />
        <Form className="mini-news-container ">
          <Form.Row>
            <Form.Control
              onChange={e => setSearchTextTemp(e.target.value)}
              placeholder={t("article.enterKeyword")}
            />
            <Button type="submit" onClick={e => onSearch(e)}>
              {t("search")}
            </Button>
          </Form.Row>
        </Form> */}
        <Row>
          {gallery && gallery.length > 0 ? (
            gallery
              .filter(item =>
                item.title.toLowerCase().includes(searchText.toLowerCase())
              )
              .slice((currentPage - 1) * pageSize, currentPage * pageSize)
              .map(item => {
                const getGalleryValue = getGalleryData(
                  item.gallery.editorRegGambar
                )
                console.log(getGalleryValue)
                return (
                  <Col md={4} key={item.id} className="mini-news-container ">
                    <Link
                      className="individual-article"
                      href={`/kegiatan/galeri/${item?.slug}`}
                    >
                      <ListGallery
                        imgSrc={getGalleryValue ? getGalleryValue[0] : ""}
                        title={item.title}
                        date={item.gallery.tanggalRegulasi}
                        count={getGalleryValue?.length}
                      />
                    </Link>
                  </Col>
                )
              })
          ) : (
            <span>{t("noData")}</span>
          )}
          {gallery && gallery.length > 0 && (
            // <div className="d-flex justify-content-center mt-4">
            //   <Pagination
            //     pagesCount={pagesCount}
            //     currentPage={currentPage}
            //     handlePageClick={handlePageClick}
            //     handlePreviousClick={handlePreviousClick}
            //     handleNextClick={handleNextClick}
            //   />
            // </div>
            <div
              className={`${classes.root} d-flex justify-content-center mt-4`}
            >
              <Pagination
                count={pagesCount}
                page={currentPage}
                onChange={handlePageClick}
                shape="rounded"
                color="primary"
              />
            </div>
          )}
        </Row>
      </Container>
    </Layout>
  )
}

export default withTrans(Index)

export const query = graphql`
  {
    allWpGalleryGks {
      nodes {
        id
        title
        slug
        language {
          code
        }
        gallery {
          tanggalRegulasi
          editorRegGambar
        }
      }
    }
  }
`
